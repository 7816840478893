<template>
  <div class="d-flex flex-row align-start justify-center w-100 h-100">
    <v-row>
      <v-col cols="4">
        <!-- <input-component
          ref="InputComponent"
          :valueWidth="vWidth"
        /> -->
        <v-row>
          <v-col cols="12">
            <span class="bg-B9D7EA text-black font-weight-bold pa-2">Block Size (ขนาดซอง)</span>
          </v-col>

          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <span>Width (ความกว้าง)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <vue-number
                  v-model="vWidth"
                  v-bind="number"
                  class="input-number"
                  :style="submitCalculate && vWidth == 0 ? 'border-color: #F44336 !important' : ''"
                  @keypress="isNumber2Decimals(vWidth, $event)"
                ></vue-number>
                <span
                  v-if="submitCalculate && vWidth == 0"
                  class="text-red"
                  style="font-size: 0.813rem;"
                >*กรุณากรอกข้อมูล</span>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>Meter (เมตร)</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <span>Height (ความสูง)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <vue-number
                  v-model="vHeight"
                  v-bind="number"
                  class="input-number"
                  :style="submitCalculate && vHeight == 0 ? 'border-color: #F44336 !important' : ''"
                  @keypress="isNumber2Decimals(vHeight, $event)"
                ></vue-number>
                <span
                  v-if="submitCalculate && vHeight == 0"
                  class="text-red"
                  style="font-size: 0.813rem;"
                >*กรุณากรอกข้อมูล</span>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>Meter (เมตร)</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <span>Depth (ความลึก)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <vue-number
                  v-model="vDepth"
                  v-bind="number"
                  class="input-number"
                  :style="submitCalculate && vDepth == 0 ? 'border-color: #F44336 !important' : ''"
                  @keypress="isNumber2Decimals(vDepth, $event)"
                ></vue-number>
                <span
                  v-if="submitCalculate && vDepth == 0"
                  class="text-red"
                  style="font-size: 0.813rem;"
                >*กรุณากรอกข้อมูล</span>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>Meter (เมตร)</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <span>Block Number (จำนวนซอง)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <vue-number
                  v-model="vNB"
                  v-bind="number"
                  class="input-number"
                  :style="submitCalculate && vNB == 0 ? 'border-color: #F44336 !important' : ''"
                  @keypress="isNumber2Decimals(vNB, $event)"
                ></vue-number>
                <span
                  v-if="submitCalculate && vNB == 0"
                  class="text-red"
                  style="font-size: 0.813rem;"
                >*กรุณากรอกข้อมูล</span>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>Block (ซอง)</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <span>Initial Feed Water Temperature (อุณหภูมิน้ำป้อนเริ่มต้น)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <vue-number
                  v-model="vTI"
                  v-bind="number"
                  class="input-number"
                  :style="submitCalculate && vTI == 0 ? 'border-color: #F44336 !important' : ''"
                  @keypress="isNumber2Decimals(vTI, $event)"
                ></vue-number>
                <span
                  v-if="submitCalculate && vTI == 0"
                  class="text-red"
                  style="font-size: 0.813rem;"
                >*กรุณากรอกข้อมูล</span>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>C (องศาเซลเซียส)</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <span>Final Feed Water Temperature (อุณหภูมิน้ำป้อนที่ต้องการลดลง)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <vue-number
                  v-model="vTF"
                  v-bind="number"
                  class="input-number"
                  :style="submitCalculate && vTF == 0 ? 'border-color: #F44336 !important' : ''"
                  @keypress="isNumber2Decimals(vTF, $event)"
                ></vue-number>
                <span
                  v-if="submitCalculate && vTF == 0"
                  class="text-red"
                  style="font-size: 0.813rem;"
                >*กรุณากรอกข้อมูล</span>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>C (องศาเซลเซียส)</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <span>Feed Time (เวลาในการแลกเปลี่ยนความร้อนแต่ละรอบ)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <vue-number
                  v-model="vFt"
                  v-bind="number"
                  class="input-number"
                  :style="submitCalculate && vFt == 0 ? 'border-color: #F44336 !important' : ''"
                  @keypress="isNumber2Decimals(vFt, $event)"
                ></vue-number>
                <span
                  v-if="submitCalculate && vFt == 0"
                  class="text-red"
                  style="font-size: 0.813rem;"
                >*กรุณากรอกข้อมูล</span>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>Minute (นาที)</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <span class="bg-B9D7EA text-black font-weight-bold pa-2">Water Pump Sizing (การคำนวณขนาดปั๊มน้ำ)</span>
          </v-col>

          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <span>Chilled Water Pipe Length (ความยาวของท่อน้ำเย็น)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <vue-number
                  v-model="vLc"
                  v-bind="number"
                  class="input-number"
                  :style="submitCalculate && vLc == 0 ? 'border-color: #F44336 !important' : ''"
                  @keypress="isNumber2Decimals(vLc, $event)"
                ></vue-number>
                <span
                  v-if="submitCalculate && vLc == 0"
                  class="text-red"
                  style="font-size: 0.813rem;"
                >*กรุณากรอกข้อมูล</span>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>Meter (เมตร)</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <span>Feed Water Pipe Length (ความยาวของท่อน้ำป้อน)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <vue-number
                  v-model="vLF"
                  v-bind="number"
                  class="input-number"
                  :style="submitCalculate && vLF == 0 ? 'border-color: #F44336 !important' : ''"
                  @keypress="isNumber2Decimals(vLF, $event)"
                ></vue-number>
                <span
                  v-if="submitCalculate && vLF == 0"
                  class="text-red"
                  style="font-size: 0.813rem;"
                >*กรุณากรอกข้อมูล</span>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>Meter (เมตร)</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <!-- <span
              v-if="submitCalculate && vLc == 0"
              class="text-red"
              style="font-size: 2.0rem;"
              >*กรุณากรอกข้อมูลให้ครบถ้วน</span> -->
            <v-btn
              :loading="calculating"
              class="mt-2 text-black font-weight-bold"
              text="Calculate (คำนวณ)"
              type="submit"
              color="#B9D7EA"
              block
              style="height: 3rem; font-size: 1.5rem;"
              @click="calculateAllValue()"
            ></v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="4"
        class="d-flex align-center justify-center"
      >
        <v-img
          contain
          src="@/assets/picture/ice_maker.png"
        ></v-img>
      </v-col>

      <v-col
        cols="4"
        class="d-flex align-start"
      >
        <!-- <output-component
          ref="OutputComponent"
        /> -->
        <v-row>
          <v-col cols="12">
            <span class="bg-B9D7EA text-black font-weight-bold pa-2">Operation Condition (เงื่อนไขการเดินระบบ)</span>
          </v-col>

          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <span>Chilled Water Flow Rate (อัตราการไหลของน้ำเย็น)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <v-text-field
                  v-model="ChilledWFRValue"
                  variant="outlined"
                  density="compact"
                  flat
                  hide-details
                  readonly
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>GPM (แกลลอนต่อนาที)</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <span>Feed Water Flow Rate (อัตราการไหลของน้ำป้อน)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <v-text-field
                  v-model="FeedWFRValue"
                  variant="outlined"
                  density="compact"
                  flat
                  hide-details
                  readonly
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>GPM (แกลลอนต่อนาที)</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <span class="bg-B9D7EA text-black font-weight-bold pa-2">Equipment List (รายการอุปกรณ์)</span>
          </v-col>

          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <span>Chiller Capacity (ขนาดเครื่องทำน้ำเย็น)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <v-text-field
                  v-model="RTValue"
                  variant="outlined"
                  density="compact"
                  flat
                  hide-details
                  readonly
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>Ton (ตัน)</span>
              </v-col>
            </v-row>
          </v-col>
          
          <!-- Start Chilled Water Pump Sizing -->
          <v-col
            cols="12"
            class="d-flex align-center"
          >
            <span>Chilled Water Pump Sizing (ขนาดของปั๊มน้ำเย็น)</span>
          </v-col>
          <v-col
            cols="2"
            class="d-flex align-center"
          >
          </v-col>
          <v-col
            cols="4"
            class="d-flex align-center"
          >
            <span>Flow Rate (อัตราการไหล)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <v-text-field
                  v-model="ChilledWPSValue"
                  variant="outlined"
                  density="compact"
                  flat
                  hide-details
                  readonly
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>GPM (แกลลอนต่อนาที)</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="2"
            class="d-flex align-center"
          >
          </v-col>
          <v-col
            cols="4"
            class="d-flex align-center"
          >
            <span>Pump Head (ความดัน)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <v-text-field
                  v-model="HCValue"
                  variant="outlined"
                  density="compact"
                  flat
                  hide-details
                  readonly
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>m.H2O (เมตรน้ำ)</span>
              </v-col>
            </v-row>
          </v-col>
          <!-- End Chilled Water Pump Sizing -->

          <!-- Start Feed Water Pump Sizing -->
          <v-col
            cols="12"
            class="d-flex align-center"
          >
            <span>Feed Water Pump Sizing (ขนาดของปั๊มน้ำป้อน)</span>
          </v-col>
          <v-col
            cols="2"
            class="d-flex align-center"
          >
          </v-col>
          <v-col
            cols="4"
            class="d-flex align-center"
          >
            <span>Flow Rate (อัตราการไหล)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <v-text-field
                  v-model="FeedWPSValue"
                  variant="outlined"
                  density="compact"
                  flat
                  hide-details
                  readonly
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>GPM (แกลลอนต่อนาที)</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="2"
            class="d-flex align-center"
          >
          </v-col>
          <v-col
            cols="4"
            class="d-flex align-center"
          >
            <span>Pump Head (ความดัน)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <v-text-field
                  v-model="HFValue"
                  variant="outlined"
                  density="compact"
                  flat
                  hide-details
                  readonly
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>m.H2O (เมตรน้ำ)</span>
              </v-col>
            </v-row>
          </v-col>
          <!-- End Feed Water Pump Sizing -->

          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <span>Feed Water Storage Tank (ปริมาตรถังน้ำป้อน)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <v-text-field
                  v-model="FeedWSTValue"
                  variant="outlined"
                  density="compact"
                  flat
                  hide-details
                  readonly
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>Cu.m (ลูกบาศก์เมตร)</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <span>Plate Heat Exchanger Number (จำนวนแผ่นแลกเปลี่ยนความร้อน) 245mm x 527mm</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <v-text-field
                  v-model="NPValue"
                  variant="outlined"
                  density="compact"
                  flat
                  hide-details
                  readonly
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>Plates (แผ่น)</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="6"
            class="d-flex align-center"
          >
            <span>Productivity (อัตราการลดอุณหภูมิ)</span>
          </v-col>
          <v-col cols="6">
            <v-row class="align-center">
              <v-col cols="6">
                <v-text-field
                  v-model="ProductivityValue"
                  variant="outlined"
                  density="compact"
                  flat
                  hide-details
                  readonly
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <span>CMH (ลูกบาศก์เมตรต่อชั่วโมง)</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import InputComponent from './Input.vue';
// import OutputComponent from './Output.vue';
import numeral from "numeral";
import { component as VueNumber } from '@coders-tm/vue-number-format';

export default {
  name: 'ProgramComponent',
  components: {
    // InputComponent,
    // OutputComponent,
    // vMaska,
    VueNumber,
  },
  data: () => ({
    vWidth: 0,
    vHeight: 0,
    vDepth: 0,
    vNB: 0,
    vTI: 0,
    vTF: 0,
    vFt: 0,
    vLc: 0,
    vLF: 0,
    number: {
      decimal: '.',
      separator: ',',
      prefix: '',
      precision: 2,
      masked: false,
    },
    calculating: false,
    submitCalculate: false,
    /* result */
    VTValue: 0,
    XValue: 0,
    YValue: 0,
    RTValue: 0,
    NPValue: 0,
    HCValue: 0,
    HFValue: 0,
    ChilledWFRValue: 0,
    FeedWFRValue: 0,
    ChilledWPSValue: 0,
    FeedWPSValue: 0,
    FeedWSTValue: 0,
    ProductivityValue: 0,
  }),
  computed: {
  },
  filters: {
    formatNumber: function (value) {
      return numeral(value).format("0,0.00");
    },
  },
  methods: {
    isNumber2Decimals(value, $event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode > 31 && (keyCode < 48 || keyCode > 57)) && (keyCode !== 46)) { // 46 is dot
        $event.preventDefault();
      }
    },
    calculateAllValue() {
      this.calculating = true;
      this.submitCalculate = true;

      if (this.vWidth > 0
        && this.vHeight > 0
        && this.vDepth > 0
        && this.vNB > 0
        && this.vTI > 0
        && this.vTF > 0
        && this.vFt > 0
        && this.vLc > 0
        && this.vLF > 0
      ) {
        /* VTValue */
        const resultVTValue = this.vNB * this.vWidth * this.vHeight * this.vDepth;
        // console.log("VTValue: ", resultVTValue)
        this.VTValue = resultVTValue;

        /* XValue */
        const resultXValue = this.VTValue * this.vTI * this.vTF * this.vFt;
        // console.log("XValue: ", Math.pow(resultXValue, 1.3))
        this.XValue = Math.pow(resultXValue, 1.3);

        /* YValue */
        const resultYValue = 29.14 * Math.pow(this.XValue, 0.8714);
        // console.log("YValue: ", resultYValue)
        this.YValue = resultYValue;

        /* RTValue */
        const valueUp = this.YValue;
        const valueDown = Math.pow(this.vTF, 2.8) * Math.pow(this.vFt, 2.3);
        const vFraction = valueUp/valueDown;
        const resultRTValue = this.vTI * Math.pow(vFraction,  1/1.11);   
        // console.log("RTValue: ", resultRTValue)
        this.RTValue = resultRTValue.toFixed(0);

        /* NPValue */
        let resultNPValue = 0;
        if (this.VTValue >= 0.5 && this.VTValue < 1) resultNPValue = 100;
        else if (this.VTValue >= 1 && this.VTValue < 1.5) resultNPValue = 125;
        else if (this.VTValue >= 1.5 && this.VTValue < 2) resultNPValue = 150;
        else if (this.VTValue >= 2 && this.VTValue < 2.5) resultNPValue = 175;
        else if (this.VTValue >= 2.5 && this.VTValue < 3) resultNPValue = 175;
        // console.log("NPValue: ", resultNPValue);
        this.NPValue = resultNPValue;

        /* HCValue */
        const resultHCValue = 40 + (0.1 * this.vLc);
        // console.log("HCValue: ", resultHCValue);
        this.HCValue = resultHCValue.toFixed(0);

        /* HFValue */
        const resultHFValue = 40 + (0.1 * this.vLF);
        // console.log("HFValue: ", resultHFValue);
        this.HFValue = resultHFValue.toFixed(0);

        /* ChilledWFRValue */
        const resultChilledWFRValue = 2.4 * resultRTValue;
        // console.log("ChilledWFRValue: ", resultChilledWFRValue);
        this.ChilledWFRValue = resultChilledWFRValue.toFixed(0);

        /* FeedWFRValue */
        const resultFeedWFRValue = 3.0 * resultRTValue;
        // console.log("FeedWFRValue: ", resultFeedWFRValue);
        this.FeedWFRValue = resultFeedWFRValue.toFixed(0);

        /* ChilledWPSValue */
        const resultChilledWPSValue = 2.4 * resultRTValue;
        // console.log("ChilledWPSValue: ", resultChilledWPSValue);
        this.ChilledWPSValue = resultChilledWPSValue.toFixed(0);

        /* FeedWPSValue */
        const resultFeedWPSValue = 3.0 * resultRTValue;
        // console.log("FeedWPSValue: ", resultFeedWPSValue);
        this.FeedWPSValue = resultFeedWPSValue.toFixed(0);

        /* FeedWSTValue */
        const resultFeedWSTValue = 1.25 * this.VTValue;
        // console.log("FeedWSTValue: ", resultFeedWSTValue);
        this.FeedWSTValue = resultFeedWSTValue.toFixed(2);

        /* ProductivityValue */
        const resultProductivityValue = (60 * this.VTValue) / this.vFt;
        // console.log("ProductivityValue: ", resultProductivityValue);
        this.ProductivityValue = resultProductivityValue.toFixed(2);

        setTimeout(() => {
          this.calculating = false;
          this.submitCalculate = false;
        }, 500);
      } else {
        this.VTValue = 0;
        this.XValue = 0;
        this.YValue = 0;
        this.RTValue = 0;
        this.NPValue = 0;
        this.HCValue = 0;
        this.HFValue = 0;
        this.ChilledWFRValue = 0;
        this.FeedWFRValue = 0;
        this.ChilledWPSValue = 0;
        this.FeedWPSValue = 0;
        this.FeedWSTValue = 0;
        this.ProductivityValue = 0;
        this.calculating = false;
      }
    }
  },
  watch: {
  },
}
</script>

<style>
.bg-B9D7EA {
  background-color: #B9D7EA !important;
}

.input-number {
  align-items: center;
  color: inherit;
  -moz-column-gap: 2px;
  column-gap: 2px;
  display: flex;
  flex-wrap: wrap;
  letter-spacing: 0.009375em;
  opacity: var(--v-high-emphasis-opacity);
  height: 40px;
  min-width: 0;
  padding: 10px;
  position: relative;
  width: 100%;
  border: 1px solid rgb(118, 118, 118);
  border-radius: 5px;
}

.v-theme--light {
  --v-disabled-opacity: 1 !important;
}

.v-field--disabled {
  background-color: #e4e4e4 !important;
  color: #000 !important;
}
</style>