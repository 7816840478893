<template>
  <v-card class="overflow-hidden">
    <top-menu />
    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto"
    >
      <div
        style="height: 100%; padding: 2rem 0 2rem 0;"
        class="overflow-y-auto px-7"
      >
        <program-component />
      </div>
    </v-sheet>
    <footer-menu />
  </v-card>
</template>

<script>
import TopMenu from './Top.vue';
import FooterMenu from './Footer.vue';
import ProgramComponent from '../program/index.vue';

export default {
  name: 'LayoutComponent',

  components: {
    TopMenu,
    FooterMenu,
    ProgramComponent,
  },

  data: () => ({
    //
  }),
}
</script>

<style scoped>
.v-sheet {
  margin-top: 6.25rem;
  height: calc(100vh - 6.25rem - 6.813rem) !important;
  max-height: calc(100vh - 6.25rem - 6.813rem) !important;
  /* background-color: #F7FBFC !important; */
  background-color: #fff;
  margin-bottom: 6.813rem !important;
}
</style>
