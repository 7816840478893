<template>
  <v-app>
    <v-main>
      <Layout />
    </v-main>
  </v-app>
</template>

<script>
import Layout from './components/layout/Layout.vue';

export default {
  name: 'App',

  components: {
    Layout,
  },

  data: () => ({
    //
  }),
}
</script>

<style>
@import "./static/style/custom.css";

.v-main {
  padding-top: 0 !important;
}
</style>
