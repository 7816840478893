<template>
  <v-app-bar
      absolute
      color="#769FCD"
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
      class="d-flex flex-row align-center justify-space-between px-3"
    >
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

      <!-- <v-toolbar-title>Title</v-toolbar-title> -->
      <div
        class="d-flex flex-row align-center justify-start h-100"
        style="width: 15rem;"
      >
        <v-img
          contain
          class="logo-web-sm"
          src="@/assets/nrct_circle_logo.png"
        ></v-img>
        <v-img
          contain
          class="logo-web-sm"
          src="@/assets/nrct_squar_logo.png"
        ></v-img>
        <v-img
          contain
          class="logo-web-sm"
          src="@/assets/en_kku_logo.png"
        ></v-img>

      </div>
      
      <span
        class="text-black font-weight-bold text-center"
        style="font-size: 2.5rem;"
      >ระบบประหยัดพลังงานการผลิตน้ำแข็งซองด้วยการให้ความเย็นเสริม</span>

        <div></div>
    </v-app-bar>
</template>

<script>
export default {
  name: 'TopMenuComponent',
}
</script>

<style>
.logo-web {
  width: fit-content !important;
  height: 3.125rem;
  max-width: 6.25rem !important;
  max-height: 3.125rem !important;
}

.logo-web-sm {
  width: fit-content !important;
  height: 5rem;
  max-width: 5rem !important;
  max-height: 5rem !important;
}

.v-toolbar__content {
  height: 6.25rem !important;
  justify-content: space-between !important;
}
</style>