<template>
  <v-footer
    class="bg-B9D7EA text-center d-flex flex-column footer-style w-100"
  >
    <!-- <div>
      <template 
        v-for="icon in icons"
      >
        <v-btn
          v-if="icon.name !== 'phone'"
          :key="icon.name"
          :icon="icon.icon"
          class="mx-4"
          variant="text"
        >
        </v-btn>
        <a
          v-else-if="icon.name == 'phone'"
          :key="icon.name"
          :href="`tel: ${icon.value}`"
          class="text-white"
        >
        <v-icon :icon="icon.icon"></v-icon>
        </a>
      </template>
    </div> -->

    <div
      class="pt-0 mt-2 d-flex flex-column justify-center align-center"
    >
      <span class="font-weight-bold">
        รศ. ดร.อนุสรณ์ ชินสุวรรณ
      </span>
      <span class="mt-1">สาขาวิชาวิศวกรรมเครื่องกล คณะวิศวกรรมศาสตร์ มหาวิทยาลัยขอนแก่น</span>
      <span class="mt-1">
        <a
          :href="`mailto: anuchi@kku.ac.th`"
          class="text-black mr-2"
        >
        <v-icon icon="mdi-email"></v-icon>
        </a>
        anuchi@kku.ac.th
      </span>
    </div>

    <v-divider></v-divider>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data: () => ({
    icons: [{
      name: 'phone',
      value: '0817391990',
      icon: 'mdi-phone',
  }],
  }),
}
</script>

<style scoped>
.bg-769FCD {
  background-color: #769FCD !important;
  color: #fff;
}

.bg-B9D7EA {
  background-color: #B9D7EA !important;
}

.footer-style {
  position: fixed;
  bottom: 0;
}

.text-white {
  color: #fff !important;
}
</style>